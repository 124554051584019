<template>
  <div class="row_contain" style="padding-top: 46px">
    <van-nav-bar
      :fixed="fixed"
      title="商品详情"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-loading size="24px" v-if="loadStart">加载中...</van-loading>
    <div class="shoppingRow" v-else>
      <van-swipe class="my-swipe" :autoplay="3000" @change="onChange">
        <van-swipe-item v-for="(image, index) in list.photo" :key="index">
          <img :src="image.pic_url" />
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ current + 1 }}/{{ list.photo.length }}
          </div>
        </template>
      </van-swipe>
      <van-cell-group>
        <van-cell :title="list.title" :label="list.des" />
        <van-cell
          :title="'￥' + list.price"
          :value="'库存:' + list.user_stock"
          title-style="color:red;font-size:18px"
        />
        <van-cell :title="list.tag_name">
          <template #default>
            <van-stepper
              v-model="numberValue"
              integer
              :min="list.user_stock > 0 ? '1' : list.user_stock"
              :max="list.user_stock"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <div class="row_bk">
        <h3>商品介绍</h3>
        <div v-html="list.details" class="me"></div>
      </div>
    </div>
    <van-goods-action>
      <van-goods-action-button
        type="warning"
        text="加入购物车"
        @click="onClickShopping"
      />
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="onClickButton"
      />
    </van-goods-action>
  </div>
</template>

<script>
export default {
  props: {
    id: {}
  },
  data () {
    return {
      loadStart: true,
      user_id: JSON.parse(localStorage.getItem('userInfo')).user_id || '0',
      list: {},
      fixed: true,
      numberValue: 1,
      border: false,
      message: '暂无介绍',
      current: 0,

    };
  },
  created () {
    this.load()
  },
  methods: {
    async load () {
      let res = await this.$http.get('goods/get_goods_details', {
        params: {
          user_id: this.user_id,
          goods_id: this.id
        }
      })
      if (res) {
        this.list = res.data.data;
        this.loadStart = false;
      }
    },
    onClickLeft () {
      this.$router.back()
    },
    async onClickShopping () {
      let value = this.$qs.stringify({
        user_id: this.user_id,
        goods_id: this.list.goods_id,
        num: this.numberValue
      })
      let res = await this.$http.post('cart/add_cart', value);
      if (res.data.error_code == 0) {
        this.$store.dispatch('CountAsync');

        this.$Toast('添加购物车成功');
      }
    },
    onClickButton () {
      this.$router.push('/orderList/' + this.id + '/2')
      console.log('立即下单')
    },
    onChange (index) {
      this.current = index;
    },
  },
}
</script>

<style>
.van-goods-action {
  bottom: 50px;
}
.me {
  width: 100%;
  min-height: 200px;
}
.my-swipe {
  width: 100%;
}
.row_bk {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0 10px;
  background: white;
}
.row_bk h3 {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
img {
  max-width: 100%;
}
.custom-indicator {
  position: absolute;
  left: 0;
  bottom: 5px;
  text-align: center;
  padding: 2px 5px;
  font-size: 12px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}
</style>